import { PersonTypes } from '../../DTO/DiagramApiDTO'
import store from '../../store/store'
import Person from './Person'

export class Partnership extends Person {
  type = PersonTypes.PARTNERSHIP
  width = 3

  createMDString(): string {
    const edgeStylePref = store.getState().project.selectedPersonId === this.id ? 'filled,bold' : 'filled'
    const nodeStylePref = store.getState().project.selectedPersonId === this.id ? '#2BBF86' : this.bgColor
    const name = this.name.replace(/ /g, '<BR/>')

    return `
        ${this.id} [id=${this.id} shape=ellipse width=${this.width}
           fillcolor="${nodeStylePref}" style="${edgeStylePref}"];
        ${this.id} [label=<
          <TABLE BORDER="0" CELLBORDER="0" CELLSPACING="2" CELLPADDING="4">
          <TR>
          <TD><FONT  POINT-SIZE="12.0"><B>${name}</B></FONT></TD>
          </TR>
          </TABLE>>];`
  }
}

export default Partnership
