import React from 'react'
import styled, { keyframes } from 'styled-components'
import polyglot from '../Translator'

const BounceAnimation = keyframes`
  0% { margin-bottom: 0; }
  50% { margin-bottom: 15px }
  100% { margin-bottom: 0 }
`
const DotWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`
const Dot: any = styled.div`
  background-color: black;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  /* Animation */
  animation: ${BounceAnimation} 0.5s linear infinite;
  animation-delay: ${(props: any) => props.delay};
`

const loading = {
  en: 'Loading',
  fr: 'Chargement'
}

const LoadingDots: React.SFC = () => {
  return (
    <DotWrapper>
      <h1>{polyglot.tr(loading)}</h1>
      <Dot delay="0s" />
      <Dot delay=".1s" />
      <Dot delay=".2s" />
    </DotWrapper>
  )
}
export default LoadingDots
