import io from 'socket.io-client'
import config from '../config'
import store from '../store/store'
import { IAppDiagramProject } from './Models/IGraphState'

class RealtimeDiagramManager {
  socket = io(config.API_ENDPOINT)
  isSubscribed: boolean = false

  constructor() {
    this.socket.on('message', (project: IAppDiagramProject) => {
      store.dispatch.project.setProject(project)
    })
  }

  subscribe(projectId: string) {
    this.socket.emit('subscribe', projectId)
    this.isSubscribed = true
  }

  unsubscribe(projectId: string) {
    this.socket.emit('unsubscribe', projectId)
    this.isSubscribed = false
  }

  updateProject(project: IAppDiagramProject) {
    if (this.isSubscribed) {
      this.socket.send(project)
    }
  }
}

export default new RealtimeDiagramManager()
