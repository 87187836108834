import { PersonTypes } from '../../DTO/DiagramApiDTO'
import store from '../../store/store'
import Person from './Person'

export class PhysicalPerson extends Person {
  type = PersonTypes.PERSON
  width = 1

  createMDString(): string {
    const names = this.name.split(',').map(e => e.trim())
    const namesString = names
      .map(name => `<TR><TD><FONT  POINT-SIZE="12.0"><B>${name.replace(/ /g, '<BR/>')}</B></FONT></TD></TR>`)
      .join('')
    const edgeStylePref = store.getState().project.selectedPersonId === this.id ? 'filled,bold' : 'filled'
    const nodeStylePref = store.getState().project.selectedPersonId === this.id ? '#2BBF86' : this.bgColor
    const result = `${this.id} [id=${this.id} shape=circle width=${
      this.width
    } fillcolor="${nodeStylePref}" style="${edgeStylePref}" ];
    ${this.id} [label=< 
          <TABLE BORDER="0" CELLBORDER="0" CELLSPACING="1" CELLPADDING="0">
          ${namesString}
          </TABLE>>];`
    return result
  }
}

export default PhysicalPerson
