export enum LanguageActionTypes {
  SWITCH_LANGUAGE = 'SWITCH_LANGUAGE'
}

interface ISwitchLanguage {
  type: LanguageActionTypes.SWITCH_LANGUAGE
}

export const switchLanguage = () => ({ type: LanguageActionTypes.SWITCH_LANGUAGE })

export type LanguageActions = ISwitchLanguage
