import { GraphDisplayActionTypes, ISetSearchTerm, IToggleUIProperty } from '../actions/GraphDisplayActions'

export interface IGraphDisplayState {
  isCreating: boolean
  showGraphSettings: string
  searchTerm: string
  showTextVersion: boolean
}

const initialState: IGraphDisplayState = {
  isCreating: false,
  showGraphSettings: null,
  searchTerm: '',
  showTextVersion: null
}

export default function graphDisplayReducer(state: IGraphDisplayState = initialState, action) {
  switch (action.type) {
    case GraphDisplayActionTypes.TOGGLE_UI_PROPERTY:
      return toggleUIProperty(state, action)

    case GraphDisplayActionTypes.INIT_GRAPH_CREATION:
      return {
        ...state,
        isCreating: true
      }

    case GraphDisplayActionTypes.END_GRAPH_CREATION:
      return {
        ...state,
        isCreating: false
      }
    case GraphDisplayActionTypes.TOGGLE_SHOW_TEXT_VERSION:
      return {
        ...state,
        showTextVersion: !state.showTextVersion
      }

    case GraphDisplayActionTypes.SET_SEARCH_TERM:
      return setSearchTerm(state, action)
    default:
      return state
  }
}

const toggleUIProperty = (state, action: IToggleUIProperty) => ({
  ...state,
  [action.payload.property]: action.payload.value
})

const setSearchTerm = (state: IGraphDisplayState, action: ISetSearchTerm) => ({
  ...state,
  searchTerm: action.payload.term
})
