import 'animate.css/animate.min.css'
import 'antd/dist/antd.css'
import 'bootstrap/dist/css/bootstrap.css'
import { Dispatch } from 'easy-peasy'
import 'loaders.css'
import 'normalize.css'
import React, { Component, lazy, Suspense } from 'react'
import { connect } from 'react-redux'
import { RouteChildrenProps } from 'react-router'
import { Redirect, Route as ReactRoute, Switch, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import './App.scss'
import { AppRoutes } from './AppRoutes'
import EmailValidation from './Base/EmailValidation/EmailValidation'
import EmailVerificationRequired from './Base/EmailValidation/EmailVerificationRequired'
import ValidationSuccess from './Base/EmailValidation/ValidationSuccess'
import IEFallback from './Base/Home/IEFallback'
import './Common.scss'
import { configureAxios } from './configureAxios'
import LoadingDots from './Diagrams/LoadingDots'
import { isIE } from './Diagrams/utils/utils'
import { RedoxUser } from './DTO/BaseApiDTO'
import './Helpers.scss'
import { IApplicationModel, IApplicationState } from './store/store'
import './Style.scss'
import polyglot from './Translator'
import WaitingComponent from './WaitingComponent'

const NotificationSystem = lazy(() => import('react-notification-system'))
const ErrorBoundary = WaitingComponent(lazy(() => import('./Components/ErrorBoundary')))

const About = WaitingComponent(lazy(() => import('./Base/About' /* webpackChunkName: "About" */)))
const ContactUs = WaitingComponent(lazy(() => import('./Base/ContactUs' /* webpackChunkName: "ContactUs" */)))
const Footer = WaitingComponent(lazy(() => import('./Base/Footer' /* webpackChunkName: "Footer" */)))
const Home = WaitingComponent(lazy(() => import('./Base/Home/Home' /* webpackChunkName: "Home" */)))
const LawFirm = WaitingComponent(lazy(() => import('./Base/LawFirm/LawFirm' /* webpackChunkName: "LawFirm" */)))
const Login = WaitingComponent(lazy(() => import('./Auth/Login' /* webpackChunkName: "Login" */)))
const Signup = WaitingComponent(lazy(() => import('./Auth/Signup' /* webpackChunkName: "Signup" */)))
const Navbar = WaitingComponent(lazy(() => import('./Base/Navbar' /* webpackChunkName: "Navbar" */)))
const NotFound = WaitingComponent(lazy(() => import('./Base/NotFound' /* webpackChunkName: "NotFound" */)))
const Pricing = WaitingComponent(lazy(() => import('./Base/Pricing/Pricing' /* webpackChunkName: "Pricing" */)))
const Privacy = WaitingComponent(lazy(() => import('./Base/Privacy' /* webpackChunkName: "Privacy" */)))
const Terms = WaitingComponent(lazy(() => import('./Base/Terms' /* webpackChunkName: "Terms" */)))
const MyDiagrams = WaitingComponent(lazy(() => import('./Diagrams/MyDiagrams' /* webpackChunkName: "MyDiagrams" */)))
const Tutorials = WaitingComponent(
  lazy(() => import('./Base/Tutorials/TutorialsIndex' /* webpackChunkName: "Tutorials" */))
)
const Graph = WaitingComponent(lazy(() => import('./Diagrams/Graph' /* webpackChunkName: "Graph" */)))
const DiagramsUnallowed = WaitingComponent(
  lazy(() => import('./Diagrams/Unallowed/DiagramsUnallowed' /* webpackChunkName: "About" */))
)

interface MaincontentProps {
  pathname: string
  // etc...
}

const Maincontent = styled.div`
  background-color: #ffffff;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  flex: 1 0 auto;
  position: relative;
  margin-top: 65px;
  margin-top: ${(props: MaincontentProps) =>
    props.pathname.includes('/fill-diagrams') ? '15px' : '66px' /* Size of the navbar + 1px border */};
`

class AppView extends Component<IProps & RouteChildrenProps> {
  notificationSystem: any

  componentDidMount() {
    polyglot.initialize()

    configureAxios()

    this.props.getUser()
  }

  addNotification = (type, message, autoDismiss) => {
    const notification = {
      title: polyglot.t(type),
      level: type,
      position: 'br',
      autoDismiss,
      action: null,
      children: null
    }
    if (typeof message === 'string') {
      notification.action = {
        label: 'OK'
      }
    } else {
      notification.children = message
    }
    this.notificationSystem.addNotification(notification)
  }

  addNotificationSuccess = message => {
    this.addNotification('success', message, 7)
  }

  addNotificationWarning = message => {
    this.addNotification('warning', message, 10)
  }

  addNotificationError = (message, support = true) => {
    if (support) {
      message = (
        <span>
          {message} {polyglot.t('teamNotified')} {polyglot.t('fillOutReportP1')}{' '}
          <a href="/contact-us?type=bug">{polyglot.t('fillOutReportP2')}</a>
          {'.'}
        </span>
      )
    } else {
      message = <span>{message}</span>
    }
    this.addNotification('error', message, 0)
  }

  notifications = {
    addNotificationSuccess: this.addNotificationSuccess,
    addNotificationWarning: this.addNotificationWarning,
    addNotificationError: this.addNotificationError
  }

  notificationSystemRef = notificationSystem => {
    this.notificationSystem = notificationSystem
  }

  render() {
    return !isIE() ? (
      <Suspense fallback={<LoadingDots />}>
        <div id="app">
          <ScrollToTop />
          <NotificationSystem ref={this.notificationSystemRef} />
          <Navbar location={this.props.location} />
          <Maincontent pathname={this.props.location.pathname}>
            <Switch>
              <Route exact path={AppRoutes.HOME} component={Home} />
              <Route exact path={AppRoutes.TERMS} component={Terms} />
              <Route exact path={AppRoutes.TUTORIALS} component={Tutorials} />
              <Route exact path={AppRoutes.PRIVACY} component={Privacy} />
              <Route
                exact
                path={AppRoutes.CONTACT}
                noContactUs
                component={ContactUs}
                componentProps={{ notifications: this.notifications }}
              />
              <Route exact path={AppRoutes.ABOUT} component={About} />
              <Route exact path={AppRoutes.PRICING} component={Pricing} />
              <Route exact path={AppRoutes.VALIDATION_SUCCESS} component={ValidationSuccess} />
              <Route exact path={AppRoutes.VALIDATION_REQUIRED} component={EmailVerificationRequired} />
              <Route exact path={AppRoutes.EMAIL_VALIDATION} component={EmailValidation} />
              <Route exact path={AppRoutes.LOGIN} component={() => <Login {...this.props} />} />
              <Route exact path={AppRoutes.SIGNUP} component={() => <Signup {...this.props} />} />
              <Route exact path={AppRoutes.UNALLOWED} component={() => <DiagramsUnallowed />} />
              <PrivateRoute
                userSignedIn={!!this.props.user}
                exact
                path={AppRoutes.MY_DIAGRAMS}
                component={MyDiagrams}
                componentProps={{
                  notifications: this.notifications
                }}
              />

              <PrivateRoute
                userSignedIn={!!this.props.user}
                exact
                path={AppRoutes.LAW_FIRM}
                component={LawFirm}
                componentProps={{
                  notifications: this.notifications
                }}
              />

              <PrivateRoute
                path={`${AppRoutes.FILL_DIAGRAMS}/:diagramId`}
                userSignedIn={!!this.props.user}
                component={Graph}
                componentProps={{ notifications: this.notifications }}
              />

              <Route component={NotFound} />
            </Switch>
          </Maincontent>
          <Footer location={this.props.location} />
        </div>
      </Suspense>
    ) : (
      <IEFallback />
    )
  }
}

interface IStateProps {
  user: RedoxUser
  language: string
}

interface IDispatchProps {
  getUser: () => void
}

type IProps = IStateProps & IDispatchProps

const mapDispatchToProps = (dispatch: Dispatch<IApplicationModel>): IDispatchProps => ({
  getUser: () => dispatch.user.getUser()
})

const mapStateToProps = (state: IApplicationState): IStateProps => {
  return {
    user: state.user.user,
    language: state.language
  }
}

const App = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppView)

export default withRouter(App)

const ScrollToTop = withRouter(
  class ScrollToTopWithoutRouter extends Component<RouteChildrenProps> {
    componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
        window.scrollTo(0, 0)
      }
    }
    render() {
      return null
    }
  }
)

const Route = ({ component: Component, componentProps = null, noContactUs = true, ...rest }) => (
  <ReactRoute
    {...rest}
    render={props => (
      <ErrorBoundary key={Component.name} contactUs={!noContactUs}>
        <Component {...props} {...componentProps} />
      </ErrorBoundary>
    )}
  />
)

const PrivateRoute = ({ userSignedIn, component, ...rest }) =>
  userSignedIn ? (
    <Route component={component} {...rest} />
  ) : (
    <ReactRoute render={props => <Redirect to={{ pathname: AppRoutes.LOGIN, state: { from: props.location } }} />} />
  )
