import { IPerson, PersonTypes } from '../../DTO/DiagramApiDTO'
import { IMDStringCreator } from '../GraphGeneration/types'
import * as utils from '../utils/utils'
import Share from './Share'

abstract class Person implements IMDStringCreator, IPerson {
  id: number = utils.randomNum()
  name: string = ''
  type: PersonTypes
  shares: Share[] = []
  childs: number[] = []
  parents: number[] = []
  width: number = 4
  bgColor: string = '#FFFFFF'

  abstract createMDString(): string

  setName(newName): Person {
    this.name = newName
    return this
  }

  setChild(childId: number): Person {
    this.childs = [...this.childs, childId]
    return this
  }

  setWidth(newWidth: number): Person {
    this.width = newWidth
    return this
  }

  setBgColor(newColor: string): Person {
    this.bgColor = newColor
    return this
  }

  setParent(parentId: number): Person {
    this.parents = [...this.parents, parentId]
    return this
  }

  deleteParent(parentId: number): Person {
    this.parents = [...this.parents.filter(el => el !== parentId)]
    return this
  }

  deleteChild(childId: number): Person {
    this.childs = [...this.childs.filter(el => el !== childId)]
    this.shares = [...this.shares.filter(el => el.target !== childId)]
    return this
  }

  clone(): Person {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this)
  }
}

export default Person
