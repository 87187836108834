import React, { useEffect } from 'react'
import styled from 'styled-components'
import { AppRoutes } from '../../AppRoutes'
import polyglot from '../../Translator'
import history from '../../Utils/history'

const Container = styled.div`
  margin: 100px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const TitleContainer = styled.div`
  margin: 40px;
  font-size: 32px;
  max-width: 400px;
`
const TextContainer = styled.div`
  font-size: 16px;
  margin: 10px;
  max-width: 400px;
`

const translations = {
  validationSuccess: {
    en: 'Email Validation Success!',
    fr: 'Validation du courriel avec succès!'
  },
  soonRedirected: {
    en: 'You will soon be redirected to your virtual law-firm space...',
    fr: 'Vous allez bientôt être redirigé(e) vers votre espace de cabinet virtuel...'
  }
}

export default function ValidationSuccess() {
  useEffect(() => {
    setTimeout(() => history.push(AppRoutes.LAW_FIRM), 5000)
  })
  return (
    <Container>
      <TitleContainer>{polyglot.tr(translations.validationSuccess)}</TitleContainer>
      <TextContainer>{polyglot.tr(translations.soonRedirected)}</TextContainer>
    </Container>
  )
}
