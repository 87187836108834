export enum GraphDisplayActionTypes {
  TOGGLE_UI_PROPERTY = 'TOGGLE_UI_PROPERTY',
  INIT_GRAPH_CREATION = 'INIT_GRAPH_CREATION',
  END_GRAPH_CREATION = 'END_GRAPH_CREATION',
  SET_SEARCH_TERM = 'SET_SEARCH_TERM',
  TOGGLE_SHOW_TEXT_VERSION = 'TOGGLE_SHOW_TEXT_VERSION'
}

export enum GraphDisplayProperties {
  IS_CREATING = 'isCreating',
  SHOW_GRAPH_SETTINGS = 'showGraphSettings',
  SHOW_SHAREHOLDER_SETTINGS = 'showShareholderSettings',
  SHOW_TEXT_VERSION = 'showTextVersion'
}

export interface IToggleUIProperty {
  type: GraphDisplayActionTypes.TOGGLE_UI_PROPERTY
  payload: { property: GraphDisplayProperties; value: boolean | string }
}
export const toggleUIProperty = (property: GraphDisplayProperties, value: boolean | string): IToggleUIProperty => {
  return {
    type: GraphDisplayActionTypes.TOGGLE_UI_PROPERTY,
    payload: { property, value }
  }
}

export interface IEndGraphCreation {
  type: GraphDisplayActionTypes.END_GRAPH_CREATION
}

export const endGraphCreation = (): IEndGraphCreation => {
  return {
    type: GraphDisplayActionTypes.END_GRAPH_CREATION
  }
}
export interface IInitGraphCreation {
  type: GraphDisplayActionTypes.INIT_GRAPH_CREATION
}

export const initGraphCreation = (): IInitGraphCreation => {
  return {
    type: GraphDisplayActionTypes.INIT_GRAPH_CREATION
  }
}
export interface IToggleShowTextVersion {
  type: GraphDisplayActionTypes.TOGGLE_SHOW_TEXT_VERSION
}
export const toggleShowTextVersion = (): IToggleShowTextVersion => {
  return {
    type: GraphDisplayActionTypes.TOGGLE_SHOW_TEXT_VERSION
  }
}

export interface ISetSearchTerm {
  type: GraphDisplayActionTypes.SET_SEARCH_TERM
  payload: { term: string }
}

export const setSearchTerm = (term: string) => {
  return {
    type: GraphDisplayActionTypes.SET_SEARCH_TERM,
    payload: { term }
  }
}
