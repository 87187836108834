import { getUserLanguage } from '../../getUserLanguage'
import { LanguageActions, LanguageActionTypes } from '../actions/LanguageActions'

export type UserLang = 'en' | 'fr'

const initialState: UserLang = getUserLanguage()

export const languageReducer = (state: UserLang = initialState, action: LanguageActions) => {
  switch (action.type) {
    case LanguageActionTypes.SWITCH_LANGUAGE:
      if (state === 'en') {
        return 'fr'
      } else {
        return 'en'
      }
    default:
      return state
  }
}
