import cookies from './Utils/cookies'

export const getUserLanguage = () => {
  const langcookie = cookies.get('language')

  if (langcookie === 'en' || langcookie === 'fr') {
    return langcookie
  } else {
    const lang = navigator.language.slice(0, 2)
    cookies.set('language', lang, { maxAge: 100000 })
    return lang === 'fr' || lang === 'en' ? lang : 'en'
  }
}
