import { createStore, reducer, Reducer, State } from 'easy-peasy'
import { IProjectModel, projectModel } from './models/ProjectModel'
import { IProjectsModel, projectsModel } from './models/ProjectsModel'
import { IUserModel, userModel } from './models/UserModel'
import graphDisplayReducer, { IGraphDisplayState } from './reducers/graphDisplayReducer'
import { languageReducer } from './reducers/languageReducer'

export interface IApplicationModel {
  projects: IProjectsModel
  project: IProjectModel
  graphDisplay: Reducer<IGraphDisplayState>
  user: IUserModel
  language: Reducer<string>
}

export type IApplicationState = State<IApplicationModel>

export const store = createStore<IApplicationModel>({
  projects: projectsModel,
  project: projectModel,
  graphDisplay: reducer(graphDisplayReducer),
  user: userModel,
  language: reducer(languageReducer)
})

export default store
