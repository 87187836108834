export enum ErrorMessages {
  NO_ADMIN_ERROR = 'NO_ADMIN',
  NO_LAWFIRM = 'NO_LAWFIRM',
  OTHER_LAWFIRM = 'OTHER_LAWFIRM',

  /* LOGIN ERRORS */
  USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  INVALID_PASSWORD = 'INVALID_PASSWORD',
  USER_NOT_SIGNED_IN = 'USER_NOT_SIGNED_IN',
  EMAIL_NOT_VERIFIED = 'EMAIL_NOT_VERIFIED'
}

export default ErrorMessages
