import { IPerson, PersonTypes } from '../../DTO/DiagramApiDTO'
import Corporation from './Corporation'
import { IAppDiagramProject } from './IGraphState'
import Partnership from './Partnership'
import Person from './Person'
import PhysicalPerson from './PhysicalPerson'
import Share from './Share'
import Trust from './Trust'

export const personAttributer = (orphan: IPerson): Person => {
  const getModel = orphan => {
    if (orphan.type === PersonTypes.CORPORATION) {
      return new Corporation()
    } else if (orphan.type === PersonTypes.PERSON) {
      return new PhysicalPerson()
    } else if (orphan.type === PersonTypes.TRUST) {
      return new Trust()
    } else if (orphan.type === PersonTypes.PARTNERSHIP) {
      return new Partnership()
    } else console.log(`error: type not found for ${orphan}`)
  }

  return Object.assign(getModel(orphan), orphan, {
    shares: orphan.shares.map(share => Object.assign(new Share({ target: orphan.id }), share))
  })
}

export const getStateFromProjectDTO = (state: IAppDiagramProject): IAppDiagramProject => {
  state.diagrams.forEach(diagram => {
    const newPersons = diagram.persons.map(person => personAttributer(person))
    diagram.persons = newPersons
  })
  return state
}
