import Logger from './Components/Logger'
import { getUserLanguage } from './getUserLanguage'
import store from './store/store'
import { AppTranslations } from './Translations'
import cookies from './Utils/cookies'

class Translator {
  locale = getUserLanguage()
  dict = AppTranslations

  initialize() {
    store.subscribe(() => {
      const lang = store.getState().language
      if (this.locale !== lang) {
        this.locale = lang
        cookies.set('language', lang, { maxAge: 100000 })
      }
    })
  }

  t = (key, objectToReplace = null) => {
    if (this.isString(key)) {
      if (!objectToReplace) {
        return this.dict[key][this.locale] || key
      } else {
        return (
          this.dict[key][this.locale].replace(/%{(\w+)}/g, (_, k) => {
            return objectToReplace[k]
          }) || key
        )
      }
    } else {
      Logger.error('the following key is not a string: ', key)
    }
  }

  tr = (key: { en: string | JSX.Element; fr: string | JSX.Element }) => {
    return key[this.locale]
  }

  // Returns if a value is a string
  isString = value => {
    return typeof value === 'string' || value instanceof String
  }
}

export const polyglot = new Translator()

export default polyglot
