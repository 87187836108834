import React from 'react'
import styled from 'styled-components'
import polyglot from '../../Translator'

const Container = styled.div`
  margin: 100px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const TitleContainer = styled.div`
  margin: 40px;
  font-size: 32px;
  max-width: 400px;
`
const TextContainer = styled.div`
  font-size: 16px;
  margin: 10px;
  max-width: 400px;
`

const translations = {
  verificationRequired: {
    en: 'Email verification required!',
    fr: "Vérification d'email requise!"
  },
  sentALink: {
    en:
      "We've sent a link to your email address so you can verify that you're the owner of your account. This email may take a few minutes to be sent to your inbox.",
    fr:
      "Nous avons envoyé un lien vers votre adresse e-mail afin que vous puissiez confirmer que vous êtes le propriétaire de votre compte. Ce courriel peut prendre quelques minutes avant d'être acheminé vers votre boite de réception."
  },
  clickLink: {
    en: "Before you can continue, please click on that link and you'll be granted access to the application.",
    fr: 'Avant de pouvoir continuer, veuillez cliquer sur ce lien pour être admissible à l’application.'
  },
  onceDone: {
    en: "Once it's done, simply refresh the page or re-sign-in to your account.",
    fr: 'Une fois fait, il vous suffira de rafraîchir la page ou de vous reconnecter à votre compte.'
  }
}

export default function EmailVerificationRequired() {
  return (
    <Container>
      <TitleContainer>{polyglot.tr(translations.verificationRequired)}</TitleContainer>
      <TextContainer>{polyglot.tr(translations.sentALink)}</TextContainer>
      <TextContainer>{polyglot.tr(translations.clickLink)}</TextContainer>
      <TextContainer>{polyglot.tr(translations.onceDone)}</TextContainer>
    </Container>
  )
}
