import { createSelector } from 'reselect'
import { PersonTypes } from '../../DTO/DiagramApiDTO'
import { IApplicationState } from '../../store/store'
import Person from '../Models/Person'
import Share from '../Models/Share'

export const personsSelector = (state: IApplicationState) => {
  const selectedDiagram = getSelectedDiagram(state)
  if (selectedDiagram) {
    return state.project.project.diagrams[state.project.selectedSlide].persons
  } else return []
}

export const getSelectedDiagram = (state: IApplicationState) =>
  state.project.project.diagrams[state.project.selectedSlide]

export const getAllPersonsName = createSelector(
  [personsSelector],
  persons => persons.map(el => el.name)
)

export const getAllCorpAndTrustName = createSelector(
  [personsSelector],
  persons => persons.filter(el => el.type !== PersonTypes.PERSON).map(el => el.name)
)
export const getAllPhysicalPersonsName = createSelector(
  [personsSelector],
  persons => persons.filter(el => el.type === PersonTypes.PERSON).map(el => el.name)
)

export const getAllBgColor = createSelector(
  [personsSelector],
  persons => {
    const colors = persons.map(el => el.bgColor)
    return [...new Set(colors)]
  }
)

export const getSelectedNode = (state: IApplicationState) => {
  const selectedDiagr = getSelectedDiagram(state)
  if (selectedDiagr) {
    return selectedDiagr.persons.find(pers => pers.id === state.project.selectedPersonId)
  } else {
    return null
  }
}

export const getShareholderWithSharesSelected = (state: IApplicationState): Person => {
  const persons = personsSelector(state)
  return persons.find(pers => pers.id === state.project.shareDisplayParentId)
}

export const getTargetChild = (state: IApplicationState) => {
  const persons = personsSelector(state)
  return persons.find(pers => pers.id === state.project.shareDisplayChildId)
}

export const getPerson = (state: IApplicationState, id: number) =>
  state.project.project.diagrams[state.project.selectedSlide].persons.find(el => el.id === id)

export const getWidth = (state: IApplicationState, id: number) => {
  const person = state.project.project.diagrams[state.project.selectedSlide].persons.find(el => el.id === id)
  if (person && person.hasOwnProperty('width')) {
    return person.width
  }
}

export const getBgColor = (state: IApplicationState, id: number) => {
  const person = state.project.project.diagrams[state.project.selectedSlide].persons.find(el => el.id === id)
  if (person && person.hasOwnProperty('bgColor')) {
    return person.bgColor
  }
}

export const getChildNames = (state: IApplicationState, id: number): string[] => {
  const childsId = getPerson(state, id).childs
  return childsId.map(id => getPerson(state, id).name)
}

export const getAllShares = (state: IApplicationState): Share[] => {
  let result: Share[] = []

  getSelectedDiagram(state).persons.forEach(pers => {
    pers.shares.forEach(share => {
      if (
        (share.nb || share.cat) &&
        !result.find(
          resShare => share.nb === resShare.nb && share.cat === resShare.cat && share.target === resShare.target
        )
      ) {
        result.push(share)
      }
    })
  })

  return result.sort((a, b) => a.nb - b.nb)
}

export const getSharesToShow = (state: IApplicationState): Share[] => {
  const parent = getPersonById(state, state.project.shareDisplayParentId)
  return parent.shares.filter(share => share.target === state.project.shareDisplayChildId)
}

export const getPersonById = (state: IApplicationState, id: number) =>
  state.project.project.diagrams[state.project.selectedSlide].persons.find(el => el.id === id)
