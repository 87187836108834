import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Redirect, RouteChildrenProps } from 'react-router'
import styled from 'styled-components'
import { APIPaths, AppRoutes } from '../../AppRoutes'
import polyglot from '../../Translator'

const Container = styled.div`
  margin: 100px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const TitleContainer = styled.div`
  margin: 40px;
  font-size: 32px;
  max-width: 400px;
`
const TextContainer = styled.div`
  font-size: 16px;
  margin: 10px;
  max-width: 400px;
`
const translations = {
  emailAlreadyVerified: {
    en: 'This email address is currently being verified or has already been verified.',
    fr: 'Cette adresse e-mail est en cours de vérification ou a déjà été vérifiée.'
  },
  clickedBefore: {
    en: 'If you have clicked the verification link before, your email is already verified.',
    fr: 'Si vous avez déjà cliqué sur le lien de vérification, votre adresse courriel est déjà vérifiée.'
  }
}

export default function EmailValidation(props: RouteChildrenProps<{ validationId: string }>) {
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    const validateEmail = async () => {
      const validId = props.match.params.validationId
      await axios.post(`${APIPaths.VALIDATE_EMAIL}`, { validationId: validId })
      setIsValid(true)
    }
    if (props.match.params.validationId) {
      validateEmail()
    }
  }, [props.match.params.validationId])

  return isValid ? (
    <Redirect to={AppRoutes.VALIDATION_SUCCESS} />
  ) : (
    <Container>
      <TitleContainer>{polyglot.tr(translations.emailAlreadyVerified)}</TitleContainer>
      <TextContainer>{polyglot.tr(translations.clickedBefore)}</TextContainer>
    </Container>
  )
}
