export interface IDiagramAllowedResponse {
  hasLawFirm: boolean
  authorized: boolean
}

export interface IDiagramProject {
  _id?: any
  id?: any
  name: string
  createdAt: Date
  status: string
  diagrams: IDiagram[]
}

export interface IDiagram {
  name: string
  persons: IPerson[]
  nodeSpacing: number
  rankSpacing: number
  fontFace: string
  showLegend: boolean
  confidentialMode: boolean
  titleSize: number
  title: string
  fontColor: string
  edgeColors: {
    [key: string]: string
  }
  xPosTitle: number
  yPosTitle: number
  footNotes: INote[]
}
export interface INote {
  id: number
  note: string
}

export enum PersonTypes {
  CORPORATION = 'Corporation',
  PERSON = 'Person',
  PARTNERSHIP = 'Partnership',
  TRUST = 'Trust'
}

export interface IPerson {
  id: number
  name: string
  type: PersonTypes
  shares: IShare[]
  childs: number[]
  parents: number[]
  width: number
  bgColor: string
}
export enum ShareRights {
  NA = 'N/A',
  ON = 'ON',
  OFF = 'OFF'
}
export interface IShare {
  key: number
  target: number
  certificateNumber: string
  nb: number
  percentage: number
  type: string
  cat: string
  participating: ShareRights
  voting: ShareRights
  dividend: ShareRights
  pbr: number
  cv: number
  vr: number
  jvm: number
  other: string
}
export enum AdminType {
  PRESIDENT = 'PRESIDENT',
  VICE_PRESIDENT = 'VICE_PRESIDENT',
  SECRETARY = 'SECRETARY',
  TRESORER = 'TRESORER',
  ADMIN = 'ADMIN'
}

export interface IAdministrators {
  id: string
  name: string
  roles: AdminType[]
}
export enum ShareType {
  ORDINARY = 'ORDINARY',
  PRIVILEGED = 'PRIVILEGED',
  CONTROL = 'CONTROL',
  ROLLOVER = 'ROLLOVER'
}
