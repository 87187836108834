import moment from 'moment'
import polyglot from '../../Translator'
import { GraphStatus } from '../GraphStatus'
import { graphTranslations } from '../graphTranslations'
import Corporation from './Corporation'
import { IAppDiagramProject } from './IGraphState'

export const getDefaultDiagramProject = (): IAppDiagramProject => {
  return {
    name: `${(polyglot && polyglot.tr(graphTranslations.newProject)) || 'Diagram project'}`,
    createdAt: moment().toDate(),
    status: GraphStatus.InProgress,
    diagrams: [
      {
        name: `${polyglot && polyglot.tr(graphTranslations.diagram1)}`,
        persons: [new Corporation().setName('Corporation 1')],
        nodeSpacing: 1,
        rankSpacing: 1,
        fontFace: 'Helvetica',
        showLegend: false,
        confidentialMode: false,
        titleSize: 24,
        title: '',
        fontColor: '#000000',
        edgeColors: null,
        xPosTitle: null,
        yPosTitle: null,
        footNotes: []
      }
    ]
  }
}
