import { AppRoutes } from '../AppRoutes'
import ErrorMessages from '../DTO/ErrorMessages'
import history from './history'

export const handleAxiosError = response => {
  let correctlyHandled = false

  if (
    response &&
    response.status === 401 &&
    response.data &&
    response.data.error === ErrorMessages.EMAIL_NOT_VERIFIED
  ) {
    history.push(AppRoutes.VALIDATION_REQUIRED)
    correctlyHandled = true
  } else if (response && response.status === 403 && response.data) {
    history.push(AppRoutes.UNALLOWED, response.data)
    correctlyHandled = true
  }

  return correctlyHandled
}
