import { notification } from 'antd'
import axios from 'axios'
import { action, Action, State, thunk, Thunk } from 'easy-peasy'
import { APIPaths, AppRoutes } from '../../AppRoutes'
import { AuthResponse, RedoxUser } from '../../DTO/BaseApiDTO'
import ErrorMessages from '../../DTO/ErrorMessages'
import polyglot from '../../Translator'
import history from '../../Utils/history'

export interface IUserModel extends IUserModelActions {
  user: RedoxUser
  loading: boolean
  error: string
}

export interface IUserModelActions {
  logIn: Thunk<IUserModel>
  logInBegin: Action<IUserModel>
  logInError: Action<IUserModel, string>
  logInSuccess: Action<IUserModel, RedoxUser>

  signUp: Thunk<IUserModel>
  signUpBegin: Action<IUserModel>
  signUpError: Action<IUserModel, string>
  signUpSuccess: Action<IUserModel, RedoxUser>

  signOut: Thunk<IUserModel>
  signOutBegin: Action<IUserModel>
  signOutError: Action<IUserModel, string>
  signOutSuccess: Action<IUserModel>

  getUser: Thunk<IUserModel>
  getUserBegin: Action<IUserModel>
  getUserError: Action<IUserModel, string>
  getUserSuccess: Action<IUserModel, RedoxUser>
}

export type IUserState = State<IUserModel>

export const userModel: IUserModel = {
  user: null,
  error: null,
  loading: false,

  logIn: thunk(async (actions, payload, { getState }) => {
    try {
      actions.logInBegin()
      const { data } = await axios.post<AuthResponse>(APIPaths.POST_LOG_IN, payload)
      actions.logInSuccess(data.user)
    } catch (error) {
      if (error.response.status === 401) {
        if (error.response.data.error === ErrorMessages.INVALID_PASSWORD) {
          notification.error({ placement: 'bottomRight', message: polyglot.t('invalidPassword') })
        } else if (error.response.data.error === ErrorMessages.USER_NOT_FOUND) {
          notification.error({ placement: 'bottomRight', message: polyglot.t('userNotFound') })
        }
      } else return actions.logInError(error.toString())
    }
  }),

  logInBegin: action(
    (state, payload): IUserState => ({
      ...state,
      loading: true,
      error: null
    })
  ),

  logInError: action(
    (state, payload): IUserState => ({
      ...state,
      loading: false,
      error: payload
    })
  ),

  logInSuccess: action(
    (state, payload): IUserState => ({
      ...state,
      loading: false,
      user: payload
    })
  ),
  signUp: thunk(async (actions, payload, { getState }) => {
    try {
      actions.signUpBegin()
      const { data } = await axios.post<AuthResponse>(APIPaths.POST_SIGN_UP, payload)
      actions.signUpSuccess(data.user)
    } catch (error) {
      if (error.response.status === 401) {
        if (error.response.data.error === ErrorMessages.USER_ALREADY_EXISTS) {
          notification.error({ placement: 'bottomRight', message: polyglot.t('userAlreadyExist') })
        }
      } else return actions.signUpError(error.toString())
    }
  }),

  signUpBegin: action(
    (state, payload): IUserState => ({
      ...state,
      loading: true,
      error: null
    })
  ),

  signUpError: action(
    (state, payload): IUserState => ({
      ...state,
      loading: false,
      error: payload
    })
  ),

  signUpSuccess: action(
    (state, payload): IUserState => ({
      ...state,
      loading: false,
      user: payload
    })
  ),

  signOut: thunk(async actions => {
    try {
      actions.signOutBegin()
      await axios.post<void>(APIPaths.POST_SIGN_OUT)
      history.push(AppRoutes.HOME)
      actions.signOutSuccess()
    } catch (error) {
      actions.signOutError(error.toString())
    }
  }),

  signOutBegin: action(
    (state, payload): IUserState => ({
      ...state,
      loading: true,
      error: null
    })
  ),

  signOutError: action(
    (state, payload): IUserState => ({
      ...state,
      loading: false,
      error: payload
    })
  ),

  signOutSuccess: action(
    (state, payload): IUserState => ({
      ...state,
      loading: false,
      error: null,
      user: null
    })
  ),

  getUser: thunk(async actions => {
    try {
      actions.getUserBegin()
      const { data } = await axios.get<AuthResponse>(APIPaths.GET_USER_INFOS)
      actions.getUserSuccess(data.user)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        actions.getUserSuccess(null)
      } else {
        actions.getUserError(error.toString())
      }
    }
  }),

  getUserBegin: action(
    (state, payload): IUserState => ({
      ...state,
      loading: true,
      error: null
    })
  ),

  getUserError: action(
    (state, payload): IUserState => ({
      ...state,
      loading: false,
      user: null,
      error: payload
    })
  ),

  getUserSuccess: action(
    (state, payload): IUserState => ({
      ...state,
      loading: false,
      error: null,
      user: payload
    })
  )
}
