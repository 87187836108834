export enum AppRoutes {
  HOME = '/',
  MY_DIAGRAMS = '/my-diagrams',
  LAW_FIRM = '/law-firm',
  FILL_DIAGRAMS = '/fill-diagrams',
  FILL_NEW_DIAGRAM = '/fill-diagrams/new',
  ABOUT = '/about',
  PRICING = '/pricing',
  EMAIL_VALIDATION = '/email-validation/:validationId',
  VALIDATION_SUCCESS = '/validation-success',
  VALIDATION_REQUIRED = '/validation-required',
  TERMS = '/terms',
  PRIVACY = '/privacy',
  CONTACT = '/contact-us',
  LOGIN = '/login',
  SIGNUP = '/sign-up',
  TUTORIALS = '/tutorials',
  UNALLOWED = '/unallowed'
}

export enum APIPaths {
  POST_SIGN_IN = '/user-signed-in',
  POST_SIGN_UP = '/sign-up',
  POST_SIGN_OUT = '/sign-out',
  POST_LOG_IN = '/sign-in',
  GET_DIAGRAMS_ALLOWED = '',
  GET_ALL_DIAGRAMS = '/diagrams',
  GET_DIAGRAM = '/diagrams',
  GET_DIAGRAMS_AUTHORIZE = '/diagrams/allowed',
  POST_DIAGRAM = '/diagrams',
  PUT_DIAGRAM = '',
  DEL_DIAGRAM = '/diagrams/',
  PDF_DOWNLOAD = '/diagrams/pdf',
  VALIDATE_EMAIL = '/validate-email',
  GET_USER_INFOS = '/user'
}
