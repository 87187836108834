import { IShare, PersonTypes, ShareRights } from '../../DTO/DiagramApiDTO'
import store, { IApplicationState } from '../../store/store'
import { IMDStringCreator } from '../GraphGeneration/types'
import { getSelectedDiagram } from '../utils/selectors'
import * as utils from '../utils/utils'
import { formatLargeNumber } from '../utils/utils'

export class Share implements IMDStringCreator, IShare {
  constructor(args: Partial<IShare>) {
    this.key = utils.randomNum()
    this.target = args.target || this.target
    this.certificateNumber = args.certificateNumber || this.certificateNumber
    this.nb = args.nb || this.nb
    this.percentage = args.percentage || this.percentage
    this.type = args.type || this.type
    this.cat = args.cat || this.cat
    this.participating = args.participating || this.participating
    this.voting = args.voting || this.voting
    this.dividend = args.dividend || this.dividend
    this.pbr = args.pbr || this.pbr
    this.cv = args.cv || this.cv
    this.vr = args.vr || this.vr
    this.jvm = args.jvm || this.jvm
    this.other = args.other || this.other
  }

  key: number = this.key
  target: any = null
  nb: number = null
  percentage: number = null
  type: string = null
  cat: string = null
  participating: ShareRights = ShareRights.NA
  voting: ShareRights = ShareRights.NA
  dividend: ShareRights = ShareRights.NA
  pbr: number = null
  certificateNumber: string = null
  cv: number = null
  vr: number = null
  jvm: number = null
  other: string = ''

  renderCorpoShare(): string {
    const state: IApplicationState = store.getState()
    const selectedDiagram = getSelectedDiagram(state)
    const confidentialMode: boolean = selectedDiagram.confidentialMode

    const certificateNumber = this.certificateNumber ? `\nCertificate: ${this.certificateNumber}` : null
    const nb = this.nb ? `${formatLargeNumber(this.nb)}` : null
    const pbr = this.pbr ? `\nACB: ${formatLargeNumber(this.pbr)} $` : null
    const vr = this.vr ? `\nCV: ${formatLargeNumber(this.vr)} $` : null
    const cv = this.cv ? `\nPUC: ${formatLargeNumber(this.cv)} $` : null
    const jvm = this.jvm ? `\nFMV: ${formatLargeNumber(this.jvm)} $` : null
    const cat = this.cat ? `'${this.cat}'` : null
    const percentage = this.percentage ? `(${this.percentage}%)` : null
    const other = utils.splitLongTextInChunks(this.other, 20, '\n')

    const participatingRight = () => {
      let participatingString
      if (state.language === 'en') {
        if (this.participating === ShareRights.NA) {
          return null
        } else if (this.participating === ShareRights.ON) {
          participatingString = '\nparticipating'
        } else return (participatingString = '\nnon-participating')
        return participatingString
      } else if (state.language === 'fr') {
        if (this.participating === ShareRights.NA) {
          return null
        } else if (this.participating === ShareRights.ON) {
          participatingString = '\nparticipantes'
        } else return (participatingString = '\nnon-participantes')
        return participatingString
      }
    }
    const votingRight = () => {
      let votingString
      if (state.language === 'en') {
        if (this.voting === ShareRights.NA) {
          return null
        } else if (this.voting === ShareRights.ON) {
          votingString = '\nvoting'
        } else return (votingString = '\nnon-voting')
        return votingString
      } else if (state.language === 'fr') {
        if (this.voting === ShareRights.NA) {
          return null
        } else if (this.voting === ShareRights.ON) {
          votingString = '\nvotantes'
        } else return (votingString = '\nnon-votantes')
        return votingString
      }
    }
    const dividendRight = () => {
      let dividendString
      if (state.language === 'en') {
        if (this.dividend === ShareRights.NA) {
          return null
        } else if (this.dividend === ShareRights.ON) {
          dividendString = '\nwith dividends'
        } else return (dividendString = '\nwithout dividends')
        return dividendString
      } else if (state.language === 'fr') {
        if (this.dividend === ShareRights.NA) {
          return null
        } else if (this.dividend === ShareRights.ON) {
          dividendString = '\navec dividendes'
        } else return (dividendString = '\nsans dividendes')
        return dividendString
      }
    }

    const shareValue = () => {
      let shareValueString = ''
      if (certificateNumber) {
        shareValueString += certificateNumber
      }
      if (pbr) {
        shareValueString += pbr
      }
      if (cv) {
        shareValueString += cv
      }
      if (jvm) {
        shareValueString += jvm
      }
      if (vr) {
        shareValueString += vr
      }
      return shareValueString
    }

    const shareValueBilingual = () => {
      if (state.language === 'fr') {
        return shareValue()
          .replace('Certificate:', 'Certificat:')
          .replace('ACB:', 'PBR:')
          .replace('CV:', 'VR:')
          .replace('PUC:', 'CV:')
          .replace('FMV:', 'JVM:')
      } else return shareValue()
    }
    const shareGeneralInfos = () => {
      let shareGeneralInfosString = ''
      if (state.language === 'en') {
        const type =
          this.type === 'ordinaires'
            ? 'ordinary'
            : this.type === 'privilégiées'
            ? 'privileged'
            : this.type === 'contrôle'
            ? 'control'
            : this.type === 'roulement'
            ? 'rollover'
            : null
        if (nb) {
          shareGeneralInfosString += ` ${nb}`
        }
        shareGeneralInfosString += ` shares`
        if (type) {
          shareGeneralInfosString += ` ${type}`
        }
        if (cat) {
          shareGeneralInfosString += ` ${cat}`
        }
        if (percentage) {
          shareGeneralInfosString += ` ${percentage}`
        }
      } else if (state.language === 'fr') {
        const type =
          this.type === 'ordinaires'
            ? 'ordinaires'
            : this.type === 'privilégiées'
            ? 'privilégiées'
            : this.type === 'contrôle'
            ? 'de contrôle'
            : this.type === 'roulement'
            ? 'de roulement'
            : null

        if (nb) {
          shareGeneralInfosString += ` ${nb}`
        }
        shareGeneralInfosString += ` actions`
        if (type) {
          shareGeneralInfosString += ` ${type}`
        }
        if (cat) {
          shareGeneralInfosString += ` ${cat}`
        }
        if (percentage) {
          shareGeneralInfosString += ` ${percentage}`
        }
      }
      return shareGeneralInfosString
    }

    if (confidentialMode) {
      return `${shareGeneralInfos()}`
    } else {
      let shareString = ''
      if (shareGeneralInfos()) {
        shareString += shareGeneralInfos()
      }
      if (participatingRight()) {
        shareString += participatingRight()
      }
      if (votingRight()) {
        shareString += votingRight()
      }
      if (dividendRight()) {
        shareString += dividendRight()
      }
      if (shareValueBilingual()) {
        shareString += shareValueBilingual()
      }
      if (other.trim().length) {
        shareString += `\n${other}`
      }

      shareString = shareString.replace(/"/g, '\\"')

      return shareString
    }
  }

  renderPartnershipShare(): string {
    let shareString = ''

    const percentage = this.percentage ? `${this.percentage}%` : null

    if (percentage) {
      shareString += percentage
    }

    const other = this.other
      .split(/((?:\w+ ){6})/g)
      .filter(Boolean)
      .join('\n')
      .trim()

    if (other && other.length) {
      console.log('other', other)
      shareString += `\n${other}`
    }
    return shareString
  }

  createMDString(): string {
    const state: IApplicationState = store.getState()
    const selectedDiagram = getSelectedDiagram(state)

    const targetPerson = selectedDiagram.persons.find(pers => pers.id === this.target)
    switch (targetPerson.type) {
      case PersonTypes.CORPORATION:
        return this.renderCorpoShare()
      case PersonTypes.PARTNERSHIP:
        return this.renderPartnershipShare()
      default:
        return ''
    }
  }
}

export default Share
