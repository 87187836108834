import { detect } from 'detect-browser'
import { AdminType } from '../../DTO/DiagramApiDTO'
import store, { IApplicationState } from '../../store/store'
import polyglot from '../../Translator'
import { graphTranslations } from '../graphTranslations'
import Person from '../Models/Person'

export const isBrowserSupported = () => {
  const browser = detect()
  let isOk = false
  switch (browser && browser.name) {
    case 'chrome':
    case 'firefox':
    case 'edge':
    case 'safari':
      isOk = true
      break

    default:
      isOk = false
  }
  return isOk
}
export const isIE = () => {
  const browser = detect()
  let isOk = false
  if (browser && browser.name && browser.name === 'ie') {
    isOk = true
  }

  return isOk
}
export const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

export const randomNum = () => {
  return new Date().valueOf()
}
const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
const numberWithSpace = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}
export const formatLargeNumber = number => {
  const state: IApplicationState = store.getState()
  if (state.language === 'en') {
    return numberWithCommas(number)
  } else {
    return numberWithSpace(number)
  }
}

export const splitLongTextInChunks = (input: string, maxLength = 20, separator = '\n') => {
  let counter: number = 0
  let result = ''
  input.split(' ').forEach((el, index) => {
    if (index !== 0) {
      result += ' '
      counter++
    }

    if (counter + el.length >= maxLength) {
      result += separator + el
      counter = el.length
    } else {
      result += el
      counter = counter + el.length
    }
  })

  return result
}

export const mergeDuplicate = (persons: Person[], duplicates: [Person, Person]) => {
  const [a, b] = duplicates

  let toKeep = persons.find(pers => pers.id === a.id)
  let toDelete = persons.find(pers => pers.id === b.id)

  // updater l'original
  toKeep.parents = [...new Set([...toKeep.parents, ...toDelete.parents])].filter(id => id !== toDelete.id)
  toKeep.childs = [...new Set([...toKeep.childs, ...toDelete.childs])].filter(id => id !== toDelete.id)

  // updater les parents avec l'unique child mergé
  const updateParent = parentId => {
    const parent = persons.find(pers => pers.id === parentId)
    parent.childs = [...new Set([...parent.childs, toKeep.id])].filter(id => id !== toDelete.id)
    parent.shares.forEach(share => {
      if (share.target === toDelete.id) {
        share.target = toKeep.id
      }
    })
  }

  toKeep.parents.forEach(updateParent)
  toDelete.parents.forEach(updateParent)

  // Updater les childs pour l'unique parent mergé
  const updateChild = childId => {
    const child = persons.find(pers => pers.id === childId)
    child.parents = [...new Set([...child.parents, toKeep.id])].filter(id => id !== toDelete.id)
  }

  toKeep.childs.forEach(updateChild)
  toDelete.childs.forEach(updateChild)

  // supprimer le duplicat
  persons = persons.filter(person => person.id !== toDelete.id)

  return persons
}

export const getDuplicates = (persons: Person[]): [Person, Person] => {
  const set = new Set()
  for (const item of persons) {
    if (set.has(item.name)) {
      let original = persons.find(el => el.name === item.name)
      return [original, item]
    } else set.add(item.name)
  }
}

export function unique<T = string>(input: T[]): T[] {
  return [...new Set(input)]
}

export const roleText = role => {
  if (role === AdminType.PRESIDENT) {
    return polyglot.tr(graphTranslations.president)
  } else if (role === AdminType.VICE_PRESIDENT) {
    return polyglot.tr(graphTranslations.vicePresident)
  } else if (role === AdminType.SECRETARY) {
    return polyglot.tr(graphTranslations.secretary)
  } else if (role === AdminType.TRESORER) {
    return polyglot.tr(graphTranslations.tresorer)
  } else {
    return polyglot.tr(graphTranslations.administrator)
  }
}
