interface IAppConfig {
  API_ENDPOINT: string
}

const dev: IAppConfig = {
  API_ENDPOINT: 'http://localhost:5000'
}

const staging: IAppConfig = {
  API_ENDPOINT: 'https://api.redox-staging.xyz'
}

const prod: IAppConfig = {
  API_ENDPOINT: 'https://api.redox-technologies.ca'
}

let config: IAppConfig

console.log('APP stage : ', process.env.REACT_APP_STAGE || 'dev')

switch (process.env.REACT_APP_STAGE) {
  case 'production':
    config = prod
    break

  case 'staging':
    config = staging
    break

  default:
    config = dev
    break
}

export default {
  ...config
}
