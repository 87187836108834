import axios from 'axios'
import { action, Action, State, thunk, Thunk } from 'easy-peasy'
import { APIPaths } from '../../AppRoutes'
import { getToggledGraphStatus } from '../../Diagrams/GraphStatus'
import { IAppDiagramProject } from '../../Diagrams/Models/IGraphState'
import { handleAxiosError } from '../../Utils/ErrorHandlingUtils'
export interface IProjectsModel extends IProjectsModelActions {
  loading: boolean
  error: string
  projects: IAppDiagramProject[]
  authorized: boolean
  hasLawFirm: boolean
}

export interface IProjectsModelActions {
  fetchProjects: Thunk<IProjectsModel>
  fetchProjectsBegin: Action<IProjectsModel>
  fetchProjectsError: Action<IProjectsModel, string>
  fetchProjectsSuccess: Action<IProjectsModel, IAppDiagramProject[]>
  checkDiagramAuthorization: Thunk<IProjectsModel>

  toggleStatus: Thunk<IProjectsModel, { id: string }>
  toggleStatusBegin: Action<IProjectsModel>
  toggleStatusError: Action<IProjectsModel, string>
  toggleStatusSuccess: Action<IProjectsModel, { id: string }>

  deleteProject: Thunk<IProjectsModel, { id: string }>
  deleteProjectBegin: Action<IProjectsModel>
  deleteProjectError: Action<IProjectsModel, string>
  deleteProjectSuccess: Action<IProjectsModel, { id: string }>
}

export type IProjectsState = State<IProjectsModel>

export const projectsModel: IProjectsModel = {
  loading: false,
  error: null,
  authorized: null,
  hasLawFirm: null,
  projects: [],
  checkDiagramAuthorization: thunk(actions => {}),

  fetchProjects: thunk(async (actions, payload, { getState }) => {
    try {
      actions.fetchProjectsBegin()
      const { data } = await axios.get<IAppDiagramProject[]>(APIPaths.GET_ALL_DIAGRAMS)
      actions.fetchProjectsSuccess(data.reverse())
    } catch (error) {
      if (!handleAxiosError(error.response)) {
        actions.fetchProjectsError(error.toString())
      }
    }
  }),

  fetchProjectsBegin: action(
    (state, payload): IProjectsState => ({
      ...state,
      loading: true,
      error: null
    })
  ),

  fetchProjectsError: action(
    (state, payload): IProjectsState => ({
      ...state,
      loading: false,
      error: payload
    })
  ),

  fetchProjectsSuccess: action(
    (state, payload): IProjectsState => ({
      ...state,
      loading: false,
      projects: payload
    })
  ),

  toggleStatus: thunk(async (actions, payload, { getState }) => {
    try {
      const project = getState().projects.find(el => el._id === payload.id)
      actions.toggleStatusBegin()
      await axios.put<IAppDiagramProject>(`/diagrams/${payload.id}`, { status: getToggledGraphStatus(project.status) })
      actions.toggleStatusSuccess(payload)
    } catch (error) {
      if (!handleAxiosError(error.response)) {
        actions.toggleStatusError(error.toString())
      }
    }
  }),

  toggleStatusBegin: action(
    (state, payload): IProjectsState => ({
      ...state,
      loading: false,
      error: null
    })
  ),

  toggleStatusError: action(
    (state, payload): IProjectsState => ({
      ...state,
      loading: false,
      error: payload
    })
  ),

  toggleStatusSuccess: action(
    (state, payload): IProjectsState => {
      state.loading = false
      const project = state.projects.find(project => project._id === payload.id)
      project.status = getToggledGraphStatus(project.status)
      return state
    }
  ),

  deleteProject: thunk(async (actions, payload, { getState }) => {
    try {
      actions.deleteProjectBegin()
      await axios.delete(`/diagrams/${payload.id}`)
      actions.deleteProjectSuccess(payload)
    } catch (error) {
      if (!handleAxiosError(error.response)) {
        actions.deleteProjectError(error.toString())
      }
    }
  }),

  deleteProjectBegin: action(
    (state, payload): IProjectsState => ({
      ...state,
      loading: true,
      error: null
    })
  ),

  deleteProjectError: action(
    (state, payload): IProjectsState => ({
      ...state,
      loading: false,
      error: payload
    })
  ),

  deleteProjectSuccess: action(
    (state, payload): IProjectsState => {
      return {
        ...state,
        loading: false,
        error: null,
        projects: state.projects.filter(project => {
          return project._id !== payload.id
        })
      }
    }
  )
}
