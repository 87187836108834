import axios from 'axios'
import { action, Action, State, thunk, Thunk } from 'easy-peasy'
import { setTimeout } from 'timers'
import { APIPaths, AppRoutes } from '../../AppRoutes'
import { graphTranslations } from '../../Diagrams/graphTranslations'
import Corporation from '../../Diagrams/Models/Corporation'
import { getDefaultDiagramProject } from '../../Diagrams/Models/DiagramProject'
import { IAppDiagramProject } from '../../Diagrams/Models/IGraphState'
import Partnership from '../../Diagrams/Models/Partnership'
import Person from '../../Diagrams/Models/Person'
import { getStateFromProjectDTO, personAttributer } from '../../Diagrams/Models/personAttributer'
import PhysicalPerson from '../../Diagrams/Models/PhysicalPerson'
import Trust from '../../Diagrams/Models/Trust'
import RealtimeDiagramManager from '../../Diagrams/RealtimeDiagramManager'
import { getDuplicates, mergeDuplicate, randomNum } from '../../Diagrams/utils/utils'
import { IDiagram, INote, PersonTypes } from '../../DTO/DiagramApiDTO'
import polyglot from '../../Translator'
import { handleAxiosError } from '../../Utils/ErrorHandlingUtils'
import history from '../../Utils/history'
import store from '../store'

const FixDuplicatesTimeout = 1000
export interface IProjectModel extends IProjectModelActions {
  loading: boolean
  saving: boolean
  error: string

  //Display properties
  selectedSlide: number
  selectedPersonId: number
  shareDisplayParentId: number
  shareDisplayChildId: number

  //data model
  project: IAppDiagramProject
}

export interface IProjectModelActions {
  fetchProject: Thunk<IProjectModel, { diagramId: string }>
  fetchProjectBegin: Action<IProjectModel>
  fetchProjectError: Action<IProjectModel, string>
  fetchProjectSuccess: Action<IProjectModel>
  setProject: Action<IProjectModel, IAppDiagramProject>
  selectDiagram: Action<IProjectModel, number>
  addCorporation: Action<IProjectModel>
  addTrust: Action<IProjectModel>
  addPhysicalPerson: Action<IProjectModel>
  addPartnership: Action<IProjectModel>
  changeType: Action<IProjectModel, { id: number; newPerson: Person }>
  selectShares: Action<IProjectModel, { id: string }>
  unSelectShares: Action<IProjectModel, { id: number }>
  setWidth: Action<IProjectModel, { index: number; width: number }>
  updateShareholder: Action<IProjectModel, { shareholderId: number; infos: Person }>
  deleteShareholder: Action<IProjectModel, { index: number }>
  addChild: Action<IProjectModel, { index: number; childName: string }>
  addParent: Action<IProjectModel, { index: number; parentName: string }>
  removeChild: Action<IProjectModel, { parentId: number; childName: string }>
  removeParent: Action<IProjectModel, { childId: number; parentName: string }>
  setGraphBgColor: Action<IProjectModel, { index: number; color: string }>
  selectShareholder: Action<IProjectModel, { id: number }>
  unSelectShareholders: Action<IProjectModel>
  setFontFace: Action<IProjectModel, { fontFace: string }>
  setFontColor: Action<IProjectModel, { color: string }>
  setGraphTitle: Action<IProjectModel, { title: string }>
  setGraphTitleSize: Action<IProjectModel, { size: number }>
  setXPosTitle: Action<IProjectModel, { xPos: number }>
  setYPosTitle: Action<IProjectModel, { yPos: number }>
  setProjectSaveName: Action<IProjectModel, { name: string }>
  setDiagramSaveName: Action<IProjectModel, { name: string }>
  toggleShowLegend: Action<IProjectModel>
  toggleConfidentialMode: Action<IProjectModel>
  setEdgeColor: Action<IProjectModel, { color: string; shareId: string }>
  setNodeSpacing: Action<IProjectModel, { spacing: number }>
  setRankSpacing: Action<IProjectModel, { spacing: number }>
  createNewFootNote: Action<IProjectModel>
  setFootNote: Action<IProjectModel, { note: INote }>
  deleteFootNote: Action<IProjectModel, { note: INote }>

  mergeDuplicates: Action<IProjectModel>

  addDiagram: Action<IProjectModel>
  deleteDiagram: Action<IProjectModel, { index: number }>
  copyDiagram: Action<IProjectModel, { index: number }>
  updateProjectSuccess: Action<IProjectModel>
  updateProjectBegin: Action<IProjectModel>
  updateProjectError: Action<IProjectModel, any>
  updateProject: Thunk<IProjectModel>

  postProjectSuccess: Action<IProjectModel, IAppDiagramProject>
  postProjectBegin: Action<IProjectModel>
  postProjectError: Action<IProjectModel, any>
  postProject: Thunk<IProjectModel>

  setDefaultProject: Action<IProjectModel>
  selectFirstPerson: Action<IProjectModel>
}

export type IProjectState = State<IProjectModel>

export const projectModel: IProjectModel = {
  loading: false,
  saving: false,
  error: null,

  selectedSlide: null,

  selectedPersonId: null,
  shareDisplayParentId: null,
  shareDisplayChildId: null,

  project: getDefaultDiagramProject(),

  selectFirstPerson: action(state => {
    const active = getActiveDiagram(state)
    const persons = active.persons
    if (persons.length) {
      state.selectedPersonId = persons[0].id
    } else {
      state.selectedPersonId = null
    }
  }),

  setDefaultProject: action(state => ({
    ...state,
    selectedSlide: 0,
    project: getDefaultDiagramProject()
  })),

  copyDiagram: action((state, payload) => {
    const toClone = state.project.diagrams.find((el, index) => index === payload.index)
    const clone = JSON.parse(JSON.stringify(toClone)) as IDiagram

    clone.name = `${clone.name} (copy)`
    const persons = clone.persons.map(person => personAttributer(person))
    state.project.diagrams.push({ ...clone, persons: persons })
    RealtimeDiagramManager.updateProject(state.project)
  }),

  deleteDiagram: action((state, payload) => {
    let selectedSlide
    if (state.project.diagrams.length === 1) {
      selectedSlide = null
    } else {
      selectedSlide = state.project.diagrams.length - 2
    }

    state = {
      ...state,
      selectedSlide,
      project: {
        ...state.project,
        diagrams: state.project.diagrams.filter((el, index) => index !== payload.index)
      }
    }

    RealtimeDiagramManager.updateProject(state.project)

    return state
  }),

  addDiagram: action((state, payload) => {
    const newState = {
      ...state,
      selectedSlide: state.project.diagrams.length,
      project: {
        ...state.project,
        diagrams: [
          ...state.project.diagrams,
          {
            name: `${polyglot.t('organizationalChart')} ${state.project.diagrams.length + 1}`,
            persons: [],
            nodeSpacing: 1,
            rankSpacing: 1,
            fontFace: 'Helvetica',
            showLegend: false,
            confidentialMode: false,
            titleSize: 24,
            title: '',
            fontColor: '#000000',
            edgeColors: null,
            xPosTitle: null,
            yPosTitle: null,
            footNotes: []
          }
        ]
      }
    }

    RealtimeDiagramManager.updateProject(newState.project)

    return newState
  }),

  updateProject: thunk(async (actions, payload, { getState }) => {
    try {
      actions.updateProjectBegin()
      const { project } = getState()
      await axios.put<IAppDiagramProject>(`/diagrams/${project._id}`, project)
      actions.updateProjectSuccess()
    } catch (err) {
      if (!handleAxiosError(err.response)) {
        actions.updateProjectError(err.toString())
      }
    }
  }),

  updateProjectSuccess: action((state, payload) => ({
    ...state,
    saving: false,
    error: null
  })),

  updateProjectBegin: action((state, payload) => ({
    ...state,
    saving: true
  })),

  updateProjectError: action((state, payload) => {
    console.error(payload)
    return {
      ...state,
      saving: false,
      error: payload
    }
  }),

  postProject: thunk(async (actions, payload, { getState }) => {
    try {
      actions.postProjectBegin()
      const project = getState().project
      const { data } = await axios.post<IAppDiagramProject>(`/diagrams`, project)
      actions.postProjectSuccess(data)
      history.push(`${AppRoutes.FILL_DIAGRAMS}/${data._id}`)
    } catch (err) {
      if (!handleAxiosError(err.response)) {
        actions.postProjectError(err.toString())
      }
    }
  }),

  postProjectSuccess: action((state, payload) => ({
    ...state,
    saving: false,
    error: null,
    project: getStateFromProjectDTO(payload)
  })),

  postProjectBegin: action((state, payload) => ({
    ...state
  })),

  postProjectError: action((state, payload) => ({
    ...state,
    saving: false,
    error: payload
  })),

  fetchProject: thunk(async (actions, { diagramId }, { getState }) => {
    try {
      actions.fetchProjectBegin()
      const { data } = await axios.get<IAppDiagramProject>(`${APIPaths.GET_DIAGRAM}/${diagramId}`)
      actions.setProject(data)
      actions.fetchProjectSuccess()
    } catch (error) {
      if (!handleAxiosError(error.response)) {
        actions.fetchProjectError(error.toString())
      }
    }
  }),

  fetchProjectBegin: action(
    (state, payload): IProjectState => ({
      ...state,
      loading: true,
      error: null
    })
  ),

  fetchProjectError: action(
    (state, payload): IProjectState => {
      console.error(payload)
      return {
        ...state,
        loading: false,
        error: payload
        // project: null}
      }
    }
  ),

  fetchProjectSuccess: action(
    (state): IProjectState => ({
      ...state,
      selectedSlide: state.project.diagrams.length ? 0 : null,
      loading: false
    })
  ),

  setProject: action((state, payload) => {
    state.project = getStateFromProjectDTO(payload)
    if (state.project.diagrams.length === 0) {
      state.selectedSlide = null
    } else if (state.selectedSlide >= state.project.diagrams.length) {
      state.selectedSlide = state.project.diagrams.length - 1
    }
  }),

  selectDiagram: action(
    (state, payload): IProjectState => ({
      ...state,
      selectedSlide: payload
    })
  ),

  addCorporation: action(
    (state, payload): IProjectState => {
      const number = getActiveDiagram(state).persons.filter(el => el.type === PersonTypes.CORPORATION).length + 1
      const newCorpo = new Corporation().setName(`Corporation ${number}`)
      getActiveDiagram(state).persons.push(newCorpo)
      state.selectedPersonId = newCorpo.id
      RealtimeDiagramManager.updateProject(state.project)
      return state
    }
  ),

  addTrust: action(
    (state, payload): IProjectState => {
      const number = getActiveDiagram(state).persons.filter(el => el.type === PersonTypes.TRUST).length + 1
      const newTrust = new Trust().setName(`${polyglot.tr(graphTranslations.trust)} ${number}`)
      getActiveDiagram(state).persons.push(newTrust)
      state.selectedPersonId = newTrust.id
      RealtimeDiagramManager.updateProject(state.project)

      return state
    }
  ),

  addPhysicalPerson: action(
    (state, payload): IProjectState => {
      const number = getActiveDiagram(state).persons.filter(el => el.type === PersonTypes.PERSON).length + 1
      const newPerson = new PhysicalPerson().setName(`${polyglot.tr(graphTranslations.physicalPerson)} ${number}`)
      getActiveDiagram(state).persons.push(newPerson)
      state.selectedPersonId = newPerson.id
      RealtimeDiagramManager.updateProject(state.project)

      return state
    }
  ),

  addPartnership: action(
    (state, payload): IProjectState => {
      const number = getActiveDiagram(state).persons.filter(el => el.type === PersonTypes.PARTNERSHIP).length + 1
      const newPerson = new Partnership().setName(`${polyglot.tr(graphTranslations.partnership)} ${number}`)
      getActiveDiagram(state).persons.push(newPerson)
      state.selectedPersonId = newPerson.id
      RealtimeDiagramManager.updateProject(state.project)
      return state
    }
  ),

  changeType: action(
    (state, payload): IProjectState => {
      getActiveDiagram(state).persons = getActiveDiagram(state).persons.map(person =>
        person.id === payload.id ? payload.newPerson : person.clone()
      )
      RealtimeDiagramManager.updateProject(state.project)
      return state
    }
  ),

  selectShares: action(
    (state, payload): IProjectState => {
      const parentId = parseFloat(payload.id.split('_')[1].replace('parent', ''))
      const childId = parseFloat(payload.id.split('_')[2].replace('child', ''))
      state.shareDisplayParentId = parentId
      state.shareDisplayChildId = childId
      return state
    }
  ),

  unSelectShares: action(
    (state, payload): IProjectState => {
      state.shareDisplayParentId = null
      state.shareDisplayChildId = null
      return state
    }
  ),

  setWidth: action(
    (state, payload): IProjectState => {
      getActiveDiagram(state).persons = getActiveDiagram(state).persons.map(person =>
        person.id === payload.index ? person.clone().setWidth(payload.width) : person.clone()
      )
      RealtimeDiagramManager.updateProject(state.project)

      return state
    }
  ),

  updateShareholder: action(
    (state, payload): IProjectState => {
      const { shareholderId: index, infos } = payload
      const updatedPersons = getActiveDiagram(state).persons.map(person => {
        if (person.id === index) {
          return Object.assign(person.clone(), infos)
        } else return person.clone()
      })
      const duplicates = getDuplicates(updatedPersons)
      if (duplicates) {
        setTimeout(() => {
          store.dispatch.project.mergeDuplicates()
        }, FixDuplicatesTimeout)
      }
      getActiveDiagram(state).persons = updatedPersons
      RealtimeDiagramManager.updateProject(state.project)
      return state
    }
  ),

  deleteShareholder: action(
    (state, payload): IProjectState => {
      getActiveDiagram(state).persons = getActiveDiagram(state).persons.reduce((acc: Person[], current: Person) => {
        const { index } = payload
        if (current.id === index) {
          return acc
        } else
          return acc.concat([
            current
              .clone()
              .deleteParent(index)
              .deleteChild(index)
          ])
      }, [])

      RealtimeDiagramManager.updateProject(state.project)

      return state
    }
  ),

  addChild: action(
    (state, payload): IProjectState => {
      const { childName, index } = payload
      const child = new Corporation()
      child.name = childName
      const updatedPersons = getActiveDiagram(state)
        .persons.map(person => {
          if (person.id === index) {
            child.setParent(person.id)
            return person.clone().setChild(child.id)
          } else return person.clone()
        })
        .concat([child])
      getActiveDiagram(state).persons = updatedPersons

      const duplicates = getDuplicates(updatedPersons)
      if (duplicates) {
        setTimeout(() => {
          store.dispatch.project.mergeDuplicates()
        }, FixDuplicatesTimeout)
      }

      RealtimeDiagramManager.updateProject(state.project)
      return state
    }
  ),
  addParent: action(
    (state, payload): IProjectState => {
      const { parentName, index } = payload
      const parent = new Corporation()
      parent.name = parentName
      const updatedPersons = getActiveDiagram(state)
        .persons.map(person => {
          if (person.id === index) {
            parent.setChild(person.id)
            return person.clone().setParent(parent.id)
          } else return person.clone()
        })
        .concat([parent])
      getActiveDiagram(state).persons = updatedPersons

      const duplicates = getDuplicates(updatedPersons)
      if (duplicates) {
        setTimeout(() => {
          store.dispatch.project.mergeDuplicates()
        }, FixDuplicatesTimeout)
      }

      RealtimeDiagramManager.updateProject(state.project)
      return state
    }
  ),

  mergeDuplicates: action((state, payload) => {
    let persons = getActiveDiagram(state).persons
    while (getDuplicates(persons)) {
      const duplicates = getDuplicates(persons)
      persons = !duplicates ? persons : mergeDuplicate(persons, duplicates)
    }
    getActiveDiagram(state).persons = persons
    RealtimeDiagramManager.updateProject(state.project)
    return state
  }),

  removeChild: action(
    (state, payload): IProjectState => {
      const child = getActiveDiagram(state).persons.find(person => person.name === payload.childName)
      if (child) {
        const newPersons = getActiveDiagram(state).persons.map(person => {
          if (person.id === payload.parentId) {
            const clone = person.clone().deleteChild(child.id)
            return clone
          } else if (person === child) {
            return child.clone().deleteParent(payload.parentId)
          } else {
            return person
          }
        })
        getActiveDiagram(state).persons = newPersons
        RealtimeDiagramManager.updateProject(state.project)
        return state
      } else {
        return state
      }
    }
  ),
  removeParent: action(
    (state, payload): IProjectState => {
      const parent = getActiveDiagram(state).persons.find(person => person.name === payload.parentName)
      if (parent) {
        const newPersons = getActiveDiagram(state).persons.map(person => {
          if (person.id === payload.childId) {
            const clone = person.clone().deleteParent(parent.id)
            return clone
          } else if (person === parent) {
            return parent.clone().deleteChild(payload.childId)
          } else {
            return person
          }
        })
        getActiveDiagram(state).persons = newPersons
        RealtimeDiagramManager.updateProject(state.project)
        return state
      } else {
        return state
      }
    }
  ),

  setGraphBgColor: action(
    (state, payload): IProjectState => {
      const newPersons = getActiveDiagram(state).persons.map(person => {
        if (person.id === payload.index) {
          return person.clone().setBgColor(payload.color)
        } else return person.clone()
      })
      getActiveDiagram(state).persons = newPersons
      RealtimeDiagramManager.updateProject(state.project)
      return state
    }
  ),

  selectShareholder: action(
    (state, payload): IProjectState => {
      state.selectedPersonId = payload.id
      return state
    }
  ),
  unSelectShareholders: action(
    (state, payload): IProjectState => {
      state.selectedPersonId = null
      return state
    }
  ),

  setFontFace: action(
    (state, payload): IProjectState => {
      getActiveDiagram(state).fontFace = payload.fontFace
      RealtimeDiagramManager.updateProject(state.project)

      return state
    }
  ),

  setFontColor: action(
    (state, payload): IProjectState => {
      getActiveDiagram(state).fontColor = payload.color
      RealtimeDiagramManager.updateProject(state.project)

      return state
    }
  ),

  setGraphTitle: action(
    (state, payload): IProjectState => {
      getActiveDiagram(state).title = payload.title
      RealtimeDiagramManager.updateProject(state.project)

      return state
    }
  ),

  setGraphTitleSize: action(
    (state, payload): IProjectState => {
      getActiveDiagram(state).titleSize = payload.size
      RealtimeDiagramManager.updateProject(state.project)

      return state
    }
  ),

  setProjectSaveName: action(
    (state, payload): IProjectState => {
      state.project.name = payload.name
      RealtimeDiagramManager.updateProject(state.project)
      return state
    }
  ),

  toggleShowLegend: action(
    (state, payload): IProjectState => {
      getActiveDiagram(state).showLegend = !getActiveDiagram(state).showLegend
      return state
    }
  ),
  setDiagramSaveName: action(
    (state, payload): IProjectState => {
      getActiveDiagram(state).name = payload.name
      RealtimeDiagramManager.updateProject(state.project)

      return state
    }
  ),

  toggleConfidentialMode: action(
    (state, payload): IProjectState => {
      getActiveDiagram(state).confidentialMode = !getActiveDiagram(state).confidentialMode
      RealtimeDiagramManager.updateProject(state.project)
      return state
    }
  ),

  setEdgeColor: action(
    (state, payload): IProjectState => {
      if (getActiveDiagram(state).edgeColors) {
        const newEdge = Object.assign(getActiveDiagram(state).edgeColors, { [payload.shareId]: payload.color })

        getActiveDiagram(state).edgeColors = newEdge
        RealtimeDiagramManager.updateProject(state.project)

        return state
      } else {
        getActiveDiagram(state).edgeColors = { [payload.shareId]: payload.color }
        RealtimeDiagramManager.updateProject(state.project)

        return state
      }
    }
  ),

  setXPosTitle: action(
    (state, payload): IProjectState => {
      getActiveDiagram(state).xPosTitle = payload.xPos
      RealtimeDiagramManager.updateProject(state.project)
      return state
    }
  ),

  setYPosTitle: action(
    (state, payload): IProjectState => {
      getActiveDiagram(state).yPosTitle = payload.yPos
      RealtimeDiagramManager.updateProject(state.project)
      return state
    }
  ),

  setNodeSpacing: action(
    (state, payload): IProjectState => {
      getActiveDiagram(state).nodeSpacing = payload.spacing
      RealtimeDiagramManager.updateProject(state.project)
      return state
    }
  ),

  setRankSpacing: action(
    (state, payload): IProjectState => {
      getActiveDiagram(state).rankSpacing = payload.spacing
      RealtimeDiagramManager.updateProject(state.project)
      return state
    }
  ),

  setFootNote: action(
    (state, payload): IProjectState => {
      const updatedNotes = getActiveDiagram(state).footNotes.map(note => {
        if (payload.note.id === note.id) {
          return payload.note
        } else return note
      })
      getActiveDiagram(state).footNotes = updatedNotes
      RealtimeDiagramManager.updateProject(state.project)
      return state
    }
  ),

  deleteFootNote: action(
    (state, payload): IProjectState => {
      const updatedNotes = getActiveDiagram(state).footNotes.filter(note => note.id !== payload.note.id)
      getActiveDiagram(state).footNotes = updatedNotes
      RealtimeDiagramManager.updateProject(state.project)
      return state
    }
  ),

  createNewFootNote: action(
    (state, payload): IProjectState => {
      getActiveDiagram(state).footNotes.push({ id: randomNum(), note: 'New note' })
      RealtimeDiagramManager.updateProject(state.project)
      return state
    }
  )
}

export const getActiveDiagram = (state: IProjectState) => state.project.diagrams[state.selectedSlide]
