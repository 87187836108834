import moment, { Moment } from 'moment'
import { IAdministrators, PersonTypes } from '../../DTO/DiagramApiDTO'
import store, { IApplicationState } from '../../store/store'
import polyglot from '../../Translator'
import { graphTranslations } from '../graphTranslations'
import { roleText, splitLongTextInChunks } from '../utils/utils'
import Person from './Person'

export class Corporation extends Person {
  type = PersonTypes.CORPORATION
  neq: string | undefined
  constitutionDate: Moment | undefined
  constitutionLaw: string | undefined
  financialYear: Moment | undefined
  admins: IAdministrators[] = []

  createMDString(): string {
    const state: IApplicationState = store.getState()
    const date =
      this.constitutionDate && state.language === 'fr'
        ? `DC: ${moment(this.constitutionDate).format('MM/DD/YYYY')}`
        : this.constitutionDate && state.language === 'en'
        ? `CD: ${moment(this.constitutionDate).format('MM//YYYY')}`
        : this.constitutionDate

    const fy =
      this.financialYear && state.language === 'fr'
        ? `FA: ${moment(this.financialYear).format('DD/MM')}`
        : this.financialYear && state.language === 'en'
        ? `FYE: ${moment(this.financialYear).format('DD/MM')}`
        : this.financialYear

    const law = this.constitutionLaw
    const neq = `NEQ: ${this.neq}`

    const administratorsString = this.admins.map((item, index) => {
      const roleString = item.roles.map((el, index) => {
        if (item.roles.length - 1 === index) {
          return `${roleText(el)}`
        } else if (item.roles.length - 2 === index) {
          return `${roleText(el)} ${polyglot.tr(graphTranslations.and)} `
        } else return `${roleText(el)}, `
      })

      if (index === 0 && roleString.join('').length) {
        return `<BR/>${polyglot.tr(graphTranslations.admin)}:<BR/>${item.name} (${roleString.join('')})`
      } else if (index === 0 && !roleString.join('').length) {
        return `<BR/>${polyglot.tr(graphTranslations.admin)}:<BR/>${item.name}`
      } else if (!roleString.join('').length) {
        return `<BR/>${item.name} `
      } else {
        return `<BR/>${item.name} (${roleString.join('')})`
      }
    })

    const edgeStylePref = store.getState().project.selectedPersonId === this.id ? 'filled,bold' : 'filled'
    const nodeStylePref = store.getState().project.selectedPersonId === this.id ? '#2BBF86' : this.bgColor

    const constitutionInfosRow = infos => `<TR><TD><FONT  POINT-SIZE="9.0">${infos}</FONT></TD></TR>`

    return `
            ${this.id} [id=${this.id} shape=rectangle, width=${
      this.width
    }, fillcolor="${nodeStylePref}", style="${edgeStylePref}"];
            ${this.id} [label=<
              <TABLE BORDER="0" CELLBORDER="0" CELLSPACING="0" CELLPADDING="0">
              <TR>
              <TD><FONT POINT-SIZE="12.0"><B>${splitLongTextInChunks(this.name, 30, '<BR/>')}</B></FONT></TD>
              </TR>
              ${date && constitutionInfosRow(date)}
              ${fy && constitutionInfosRow(fy)}
              ${law && constitutionInfosRow(law)}
              ${this.neq && constitutionInfosRow(neq)}
              ${this.admins.length && constitutionInfosRow(administratorsString.join(''))}
              </TABLE>>]
              `
  }
}

export default Corporation
