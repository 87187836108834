import { PersonTypes } from '../../DTO/DiagramApiDTO'
import store from '../../store/store'
import polyglot from '../../Translator'
import { graphTranslations } from '../graphTranslations'
import Person from './Person'

export class Trust extends Person {
  type = PersonTypes.TRUST
  fiduciaires = []

  createMDString(): string {
    const fiduciaryString =
      this.fiduciaires.length &&
      this.fiduciaires.map((item, index) => {
        if (this.fiduciaires.length - 1 === index) {
          return `${item}`
        } else if (this.fiduciaires.length - 2 === index) {
          return `${item} ${polyglot.tr(graphTranslations.and)}<BR/>`
        } else return `${item},<BR/>`
      })

    const edgeStylePref = store.getState().project.selectedPersonId === this.id ? 'filled,bold' : 'filled'
    const nodeStylePref = store.getState().project.selectedPersonId === this.id ? '#2BBF86' : this.bgColor
    const name = this.name.replace(/ /g, '<BR/>')
    const constitutionInfosRow = infos =>
      `<TR><TD><FONT  POINT-SIZE="10.0">${
        this.fiduciaires.length === 1
          ? polyglot.tr(graphTranslations.fiduciary)
          : polyglot.tr(graphTranslations.fiduciaries)
      }: ${infos}</FONT></TD></TR>`

    return `
        ${this.id} [id=${this.id} shape=triangle width=${this.width}
           fillcolor="${nodeStylePref}" style="${edgeStylePref}"];
        ${this.id} [label=<
          <TABLE BORDER="0" CELLBORDER="0" CELLSPACING="2" CELLPADDING="4">
          <TR>
          <TD><FONT  POINT-SIZE="12.0"><B>${name}</B></FONT></TD>
          </TR>
          ${this.fiduciaires.length && constitutionInfosRow(fiduciaryString.join(''))}
          </TABLE>>];`
  }
}

export default Trust
